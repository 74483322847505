import api from '../clients/axios';

const resource = '/api/v1';

export default {
  async getCustomersByName(params) {
    const response = await api.get(`${resource}/customers`, { params });
    return response.data;
  },
  async getAllModes() {
    const response = await api.get(`${resource}/modes`);
    return response.data;
  },
  async getAllCarriers() {
    const response = await api.get(`${resource}/carriers`);
    return response.data;
  },
  async getAllAccessorials(params) {
    const response = await api.get(`${resource}/accessorials`, { params });
    return response.data;
  },
  async sendQuote(payload) {
    const response = await api.post(`${resource}/quotes/send-quote`, payload);
    return response;
  },
  async getQuoteHistory(params) {
    const response = await api.get(`${resource}/quotes/history`, { params });
    return response;
  },
  async cancelScale(scaleId) {
    const response = await api.delete(`${resource}/quotes/cancel/${scaleId}`);
    return response;
  },
};
