const ScalesByWeightRoute = [
  {
    path: '/scales-by-weight',
    name: 'ScalesByWeight',
    component: () => import('../../views/ScalesByWeight/Index.vue'),
    props: true,
  },
];

export default ScalesByWeightRoute;
