import UserRepository from './UserRepository';
import ScaleRepository from './ScaleRepository';
import ScaleByWeightRepository from './ScaleByWeightRepository';

const repositories = {
  users: UserRepository,
  scales: ScaleRepository,
  scalesByWeight: ScaleByWeightRepository,
};

export default {
  service: (name) => repositories[name],
};
