import api from '../clients/axios';

const resource = '/api/v1';

export default {
  async getLocations(params) {
    const response = await api.get(`${resource}/locations`, { params });
    return response.data;
  },
};
