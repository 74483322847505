import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import TieredMenu from 'primevue/tieredmenu';
import Button from 'primevue/button';
import AutoComplete from 'primevue/autocomplete';
import Checkbox from 'primevue/checkbox';
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import Tooltip from 'primevue/tooltip';
import Dialog from 'primevue/dialog';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import Vue from 'vue';

Vue.component('DataTable', DataTable);
Vue.component('Column', Column);
Vue.component('ColumnGroup', ColumnGroup);
Vue.component('TieredMenu', TieredMenu);
Vue.component('Button', Button);
Vue.component('Checkbox', Checkbox);
Vue.component('AutoComplete', AutoComplete);
Vue.component('MultiSelect', MultiSelect);
Vue.component('Dropdown', Dropdown);
Vue.component('Dialog', Dialog);
Vue.directive('tooltip', Tooltip);

Vue.use(PrimeVue);
