const EditScalesByWeightRoute = [
  {
    path: '/edit-scales-by-weight',
    name: 'EditScalesByWeight',
    component: () => import('../../views/ScalesByWeight/components/TableScalesByWeight/Index.vue'),
    props: true,
  },
];

export default EditScalesByWeightRoute;
